<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <event-bio-panel
          v-if="event"
          :data="event"
          :handle-update="updateData"
        ></event-bio-panel>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-tabs
          v-model="eventTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
            :append="tab.append"
            :to="tab.to"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="eventTab"
          class="mt-5 pa-1"
        >
          <v-tab-item
            v-if="event"
            value="overview"
          >
            <event-tab-overview :data="event"></event-tab-overview>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline, no-unused-vars
import { onUnmounted, ref, provide } from '@vue/composition-api'
import { mdiAccountOutline } from '@mdi/js'
import { useRouter } from '@core/utils'

import useEventView from './useEventView'
import EventBioPanel from './event-bio-panel/EventBioPanel.vue'
import EventTabOverview from './event-tab-overview/EventTabOverview.vue'

export default {
  components: {
    EventBioPanel,
    EventTabOverview,
  },
  setup() {
    const eventTab = ref(null)
    const { route } = useRouter()

    const {
      event,
      loading,
      updating,

      errorMessages,

      init,
      destroy,

      updateData,
    } = useEventView(route.value.params.id)

    // Init
    init()

    // Destroy
    onUnmounted(() => {
      destroy()
    })

    const tabs = [
      {
        icon: mdiAccountOutline,
        title: 'Overview',
        append: true,
        to: '#overview',
      },
    ]

    provide('eventUpdating', updating)
    provide('eventErrorMessages', errorMessages)

    return {
      tabs,
      eventTab,

      event,
      loading,
      updating,
      updateData,

      errorMessages,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
