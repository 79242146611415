import { computed, getCurrentInstance, ref } from '@vue/composition-api'

export default function useEventsList() {
  const vm = getCurrentInstance().proxy

  const eventListTable = ref([])

  const tableColumns = [
    { text: '#', value: 'id', width: '5%' },
    { text: 'TITLE', value: 'title', width: '45%' },
    { text: 'CATEGORY', value: 'category', width: '20%' },
    { text: 'DATE', value: 'startDate', width: '10%' },
    { text: 'PRICE', value: 'price', width: '10%' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
      width: '10%',
    },
  ]

  const events = computed({
    get: () => vm.$store.getters['events/items'],
  })
  const loading = computed({
    get: () => vm.$store.getters['events/loading'],
  })
  const meta = computed({
    get: () => vm.$store.getters['events/meta'],
  })
  const filters = computed({
    get: () => vm.$store.getters['events/filters'],
  })
  const options = computed({
    get: () => vm.$store.getters['events/options'],
    set: val => vm.$store.dispatch('events/setOptions', { ...options.value, ...val }),
  })
  const search = computed({
    get: () => vm.$store.getters['events/search'],
    set: val => vm.$store.dispatch('events/setSearch', val),
  })
  const totalEventListTable = computed({
    get: () => vm.$store.getters['events/meta'].total,
  })

  const fetchItems = () => vm.$store.dispatch('events/fetchItems')
  const setOptions = val => vm.$store.dispatch('events/setOptions', val)
  const setFilters = val => vm.$store.dispatch('events/setFilters', val)

  const init = () => {
    fetchItems()
  }

  const destroy = () => {}

  return {
    eventListTable,
    tableColumns,

    totalEventListTable,
    loading,

    fetchItems,

    meta,
    events,
    options,
    filters,
    search,

    setOptions,
    setFilters,

    init,
    destroy,
  }
}
